import * as React from "react";
import * as DisplayComponents from "../components/DisplayComponents.js";
import { codebase, canvases } from "./codebase.js";
import TicTacToe from "../components/TicTacToe";
// import { GetFireBaseImage } from './firebase.js';

export const getProjectMediaURL = (path) => {
    if (path === null || path === undefined) {
        return null;
    }
    return "https://storage.googleapis.com/yk-personal-website.appspot.com" + path;
}

export const processComponent = (component, projectID) => {
    switch (component.type) {
        case "GradientBack":
            return (
                <DisplayComponents.GradientBack
                    color={component.color}
                    mini={component.mini}
                />
            );
        case "TicTacToe":
            return(
                <TicTacToe version={component.version}/>
            );
        case "Title":
            return (
                <DisplayComponents.Title 
                    title={component.title} 
                    subtitle={component.subtitle} 
                    src={component.src.loadReady ? component.src.src : getProjectMediaURL(component.src.src)}
                    caption={component.caption} />
            );
        case "Notes":
            return (
                <DisplayComponents.Notes
                    sectionTitle={component.sectionTitle ? component.sectionTitle : ""}
                    text={component.text}
                    addSpacer={component.addSpacer}
                />
            );
        case "Bullets":
            return (
                <DisplayComponents.Bullets
                    bullets={component.bullets}
                    bulletType={component.bulletType}
                    returnRaw={component.returnRaw}
                />
            );
        case "Text":
            return (
                <DisplayComponents.Text
                    text={component.text}
                    style={component.style}
                    indent={component.indent}
                    sectionTitle={component.sectionTitle}
                    sectionSubTitle={component.sectionSubTitle}
                    textAlign={component.textAlign}
                    isNote={component.isNote}
                />
            );
        case "Image":
            return (
                <DisplayComponents.Image
                    src={component.src.loadReady ? component.src.src : getProjectMediaURL(component.src.src)}
                    caption={component.caption}
                    classNames={component.classNames}
                    ID={component.ID}
                    style={component.style}
                />
            );
        case "Video":
            return (
                <DisplayComponents.Video
                    src={component.src.loadReady ? component.src.src : getProjectMediaURL(component.src.src)}
                    caption={component.caption}
                    classNames={component.classNames}
                    ID={component.ID}
                    style={component.style}
                />
            );
        case "Tool":
            return (
                <DisplayComponents.Tool
                    toolTip={component.tooltip} 
                    tipPosition={component.tipposition}
                    classNames={component.classnames}
                    IDs={component.IDs}
                    content={component.content}
                    toolFunction={component.toolfunction}
                />
            );
        case "Code":
            return (
                <DisplayComponents.Code
                    type={component.subtype}
                    gistID={component.gistID}
                    codeBlock={Number.isInteger(component.blocknumber) ? codebase[projectID][component.blocknumber] : component.codeBlock}
                    language={component.language}
                    color={component.color}
                    caption={component.caption}
                    style={component.style}
                />
            );
        case "LeftRightComponent":
            return (
                <DisplayComponents.LeftRightComponent
                    leftComponent={processComponent(component.leftComponent, projectID)}
                    rightComponent={processComponent(component.rightComponent, projectID)}
                    classNames={component.classNames}
                    shrinkLeft={component.shrinkLeft}
                    shrinkRight={component.shrinkRight}
                />
            );
        case "TopBottomComponent":
            return (
                <DisplayComponents.TopBottomComponent
                    topBlock={processComponent(component.topBlock, projectID)}
                    bottomBlock={processComponent(component.bottomBlock, projectID)}
                />
            );
        case "P5":
            return (
                <DisplayComponents.P5
                    fnPackages={canvases[component.projectID]}
                />
            );
        case "EmbeddedSite":
            return (
                <DisplayComponents.EmbeddedSite
                    src={component.src}
                    title={component.title}
                    style={component.style}
                />
            );
        case "Equation":
            return (
                <DisplayComponents.Equation
                    text={component.text}
                    style={component.style}
                />
            );
        case "ComplexSection":
            return (
                <DisplayComponents.ComplexSection
                    isSubsection={component.isSubsection}
                    componentData={component.componentData}
                />
            );
        default:
            console.log("Error: Default block reached (uilities.js -> process components).")
            return <DisplayComponents.Code/>;
    }
}

export const processComponentData = (componentData) => {
    const unprocessedComponents =  componentData["components"];
    const processedComponents = unprocessedComponents.map((component) => {
        return processComponent(component, componentData?.projectID);
    });
    return processedComponents;
}

export const generateCards = (dataArray, mini=false) => {
    return (
        dataArray.map((item) => {
            return (
                <DisplayComponents.Card
                    key={item.name+item.projectID}
                    data={item}
                    mini={mini}
                />
            );
        })
    );
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};