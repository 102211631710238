import * as React from 'react';
import '../css/Other.css';
import { logAnalytic, severance } from "../utilities/firebase";
import { constants } from '../utilities/constants';

// https://stackoverflow.com/questions/442404/retrieve-the-position-x-y-of-an-html-element
const getOffset = (el) => {
    let _x = 0;
    let _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

let key = "";

const Other = () => {
    React.useEffect(() => {
        logAnalytic(constants.OTHER_ANALYTIC_EVENT);

        // Severance *********************************************************
        const numbers = document.querySelectorAll('.numbers div');

        const checkKey = async (k) => {
            const response = await severance({key: k});
            const data = await response.data;

            if (data.message === 'Incorrect Key') {
                key = "";
                document.querySelector('.header-percent').innerHTML = '0% Complete, Incorrect Key';
            } else if (data.message === 'Correct Key') {
                document.querySelector('.numbers').remove();
                document.querySelector('.code').remove();
                document.querySelector('.note').remove();
                document.querySelector('.severance-package').innerHTML = data['severance-package'];
            }
            console.log(data);
        }

        numbers.forEach((div) => {
            const random_num = Math.floor(Math.random() * 10);
            div.innerHTML = random_num;

            const animation_roll = Math.floor(Math.random() * 10);
            const delay_roll = Math.floor(Math.random() * 10);
            const duration_roll = Math.floor(Math.random() * 10);

            if (animation_roll <= 1) {
                div.classList.add('animation-1');
            } else if (animation_roll <= 3) {
                div.classList.add('animation-2');
            } else if (animation_roll <= 5) {
                div.classList.add('animation-3');
            } else if (animation_roll <= 6) {
                div.classList.add('animation-4');
            }

            if (div.classList.length !== 0) {
                if (delay_roll <= 1) {
                    div.classList.add('delay-1');
                } else if (delay_roll <= 3) {
                    div.classList.add('delay-2');
                } else if (delay_roll <= 6) {
                    div.classList.add('delay-3');
                }

                if (duration_roll <= 2) {
                    div.classList.add('duration-1');
                } else if (duration_roll <= 4) {
                    div.classList.add('duration-2');
                } else if (duration_roll <= 6) {
                    div.classList.add('duration-3');
                }
            }

            if (div.classList.length === 0) {
                div.classList.add('s');
            } else {
                div.classList.add('n');
            }

            if ("012379".includes(String(random_num)) && div.classList.contains('s')) {
                div.classList.add('k');
            }

            div.addEventListener('click', () => {
                if (div?.clicked) {
                    return;
                }
                div.clicked = true;

                if ("012379".includes(div.innerHTML)) {
                    if (key.length < 6) {
                        key += div.innerHTML;
                        document.querySelector('.header-percent').innerHTML = `${Math.floor((key.length/6) * 100)}% Complete`;
                        if (key.length === 6) {
                            console.log("checking key:", key);
                            checkKey(key);
                        }
                    }
                    div.style.color = "#FDF06E";
                } else {
                    div.style.color = "#051f34";
                }
            });
        });

        document.addEventListener('mousemove', (e) => {
            const x = e.pageX;
            const y = e.pageY;
            numbers.forEach((div) => {
                const div_x = getOffset(div).left + 50; // 50 equal half div height
                const div_y = getOffset(div).top + 50; 
                const dist = Math.sqrt(((div_x - x) ** 2) + ((div_y - y) ** 2))

                if (dist <= 75) {
                    div.style.fontSize = "58px";
                } else if (dist <= 125) {
                    div.style.fontSize = "48px";
                } else if (dist <= 175) {
                    div.style.fontSize = "36px";
                } else {
                    div.style.fontSize = "24px";
                }

                // if (dist <= 175 && div.classList.contains('s') && !div.classList.contains('found')) {
                //     div.classList.add('kanim');
                // }
            });
        });
    }, []);

    return (
        <div className='other-container'>
            <div className="severance-package"></div>

            <div className="numbers">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <footer className="other-footer">
                <div className="code">
                    0x 7472792D : 0x 33313937 : 0x 32302D6D : 
                    0x 69676874 : 0x 2D776F72 : 0x 6B000000
                </div>
                <div className="note">
                    *Note: This page was created by recreating &nbsp;
                    <a  id="iar" 
                        href="https://github.com/iam-robin/severance-interface" 
                        target="_blank" 
                        rel="noopener noreferrer">
                            iam-robin's 
                    </a>
                    &nbsp; version of the Severance interface, as seen on Apple TV
                </div>
            </footer>
        </div>
    );
}

export default Other