import * as React from 'react';
import '../css/Contact.css';
import { logAnalytic } from '../utilities/firebase';
import { constants } from '../utilities/constants';

const Contact = () => {

    React.useEffect(() => {
        logAnalytic(constants.CONTACT_ANALYTIC_EVENT);
    }, []);

    return (
        <div className="contact-wrapper">
            <div className="background-contact">
                <ul id="contact-icons">
                    <li id="em"><a href="mailto:aak.development@gmail.com"target="_blank" rel="noopener noreferrer"><i className="fas fa-envelope fa-3x"></i></a></li>
                    <li id="ln"><a href="https://www.linkedin.com/in/yemi-kelani/"target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin fa-3x"></i></a></li>
                    <li id="ig"><a href="https://www.instagram.com/swordlinke/?hl=en"target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-3x"></i></a></li>
                    <li id="gt"><a href="https://github.com/yemi-kelani"target="_blank" rel="noopener noreferrer"><i className="fab fa-github fa-3x"></i></a></li>
                </ul>
            </div>
        </div>
    );
}

export default Contact