import * as React from "react";
import "../css/Blog.css";
import * as DisplayComponents from "../components/DisplayComponents.js";
import { useNavigate } from 'react-router-dom';
import { logAnalytic } from "../utilities/firebase.js";
import { constants } from "../utilities/constants.js";

const BlogCard = ({ cardMeta }) => {
    console.log("Meta", cardMeta);
    
    const navigate = useNavigate();

    const setContentToClickedCard = () => {
        if (cardMeta.info.id !== null && cardMeta.info.id !== undefined) {
            navigate('/blog/' + cardMeta.info.id);
        } else {
            console.log("Error navigating to blog post. Check BlogCard component...)")
        }
    }
    
    return (
        <div 
            className={"card-container-blog"} 
            onClick={setContentToClickedCard}
        >
            <div className="blog-card-segment">
                {
                    cardMeta.info.relatedProjectID !== undefined
                    ? <h4><DisplayComponents.Label label={"Related Project"}/></h4>
                    : <></>
                }
                {
                    cardMeta.info.labels.includes("Research")
                    ? <h4><DisplayComponents.Label label={"Research"}/> {cardMeta.type}</h4>
                    : <></>
                }
                <h4>{cardMeta.info.title}</h4>
                <hr className="card-divider"></hr>
                <div>
                    {cardMeta.info.labels.map((label) => {
                        return (
                            label.toLowerCase() !== "research"
                            ? <DisplayComponents.Label key={label} label={label}/>
                            : <></>
                        )
                    })}
                </div>
            </div>

            <div 
                className="blog-card-segment"
            >
                <p><b>Summary:&nbsp;</b>
                    {
                        cardMeta.info.abstract.length > 435
                        ? cardMeta.info.abstract.slice(0,435) + "..."
                        : cardMeta.info.abstract
                    }
                </p>
            </div>
        </div>
    );

};
 
const Blog = ({ blogData }) => {

    React.useEffect(()=>{
        if(typeof window?.MathJax !== "undefined"){
            // load equations with MathJax 
            window.MathJax.typeset()
        }

        logAnalytic(constants.BLOG_ANALYTIC_EVENT);
    },[]);

    return (
        <div className="blog-wrapper">
            <div className="blog-content">

                <DisplayComponents.GradientBack
                    mini={true}
                    color="veryberry-4"
                />

                <div className="blog-card-container">
                    {
                        blogData.map((blogPost, index) => {
                            return(
                                <BlogCard
                                    cardMeta={blogPost.metadata}
                                    key={`${blogPost.metadata.info.title}-${index}`}
                                />
                            )
                        }).reverse()
                    }
                </div>

            </div>
        </div>
    );
};

export default Blog;
