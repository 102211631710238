import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { generateCards } from "../utilities/utilities.js"

// P5 Dependenices ***********************************************************
import Sketch from "react-p5";

// Text Block Dependencies ***************************************************
import parse from 'html-react-parser';

// Card Dependencies *********************************************************
import { processComponentData, getProjectMediaURL } from "../utilities/utilities";

// Code Block Dependencies ***************************************************
import Gist from "react-gist";
// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import csharp from 'react-syntax-highlighter/dist/esm/languages/prism/csharp';
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('csharp', csharp);

// Title *********************************************************************
export const Title = ({title, subtitle, src, caption}) => {
    if (title !== undefined && title !== null) {
        return (
            <div className="title-container">
                <h1 
                    style={src !== null && src !== undefined ? { color: "white"} : {}}
                    className="title"
                >{title}</h1>
                {
                    src !== null && src !== undefined 
                    ? <>
                        <GradientBack/>
                        <div className={`title-image-container`} style={{width: "90%"}}>
                            <img id={`title-image`} src={src} alt=""/>
                            <caption style={{fontSize: "smaller"}}>{caption}</caption>
                        </div>
                    </>  
                    : <GradientBack mini={true}/>}
            </div>
        );
    } else {
        return (
            <div className="title-container">
                <h2 className="subtitle">{subtitle}</h2>
                {caption !== undefined && caption !== null ? <caption>{caption}</caption> : <></>}
            </div>
        );
    }
};

// Notes *********************************************************************
export const Notes = ({sectionTitle="Notes", text, addSpacer=false}) => {
    return (
        <div className="notes-container">
            <h2>{sectionTitle}</h2>
            <hr className="solid"></hr>
            {text !== undefined && text !== null ? <Text text={text} isNote={true}/> : <></>}
            {addSpacer ? <div className="note-spacer"></div> : <></>}
        </div>
    );
};

// Bullets *******************************************************************
const ULList = ({children, bulletType="circle"}) => {
    const options = [
        "disc", 
        "circle", 
        "square"
    ];
    const defaultType = "circle";

    return (
        <ul style={{
            "list-style-type": options.includes(bulletType) 
                ? bulletType 
                : defaultType,
            "line-height": "180%"
        }}>
            {children}
        </ul>
    );
};

const OLList = ({children, bulletType="decimal"}) => {
    const options = [
        "armenian",
        "cjk-ideographic",
        "decimal",
        "decimal-leading-zero",
        "georgian",
        "hebrew",
        "hiragana",
        "hiragana-iroha",
        "katakana",
        "katakana-iroha",
        "lower-alpha",
        "lower-greek",
        "lower-latin",
        "lower-roman",
        "upper-alpha",
        "upper-latin",
        "upper-roman",
        "none",
        "inherit",
    ];
    const defaultType = "decimal";

    return (
        <ol style={{
            "listStyleType": options.includes(bulletType) 
                ? bulletType 
                : defaultType,
            "line-height": "180%"
        }}>
            {children}
        </ol>
    );
};

export const Bullets = ({bullets, bulletType="circle", returnRaw=false}) => {
    const bulletPoints = bullets.map((bulletpoint) => {
        if (typeof bulletpoint === "object" && !Array.isArray(bulletpoint)) {
            return (
                <li>
                    {bulletpoint.leadText}
                    <Bullets 
                        returnRaw={bulletpoint?.returnRaw}
                        bullets={bulletpoint.bulletPoints}
                        bulletType={
                            bulletpoint?.bulletType
                                ? bulletpoint.bulletType
                                : bulletType === "circle"
                                    ? "disc"
                                    : bulletType === "disc"
                                        ? "circle"
                                        : bulletType === "decimal"
                                            ? "lower-alpha"
                                            : "decimal"
                        }
                    />
                </li>
            );
        } else if (typeof bulletpoint === "string"
                    && bulletpoint[0] === "<" 
                    && bulletpoint[bulletpoint.length - 1] === ">") {
            return parse(bulletpoint);
        } else {
            return <li>{bulletpoint}</li>;
        }
    });

    const bulletPointsHTML = ["disc", "circle", "square"].includes(bulletType)
        ? <ULList children={bulletPoints} bulletType={bulletType}/>
        : <OLList children={bulletPoints} bulletType={bulletType}/>;

    return (
        returnRaw
            ? bulletPointsHTML
            :<div className="bullet-container">
                {bulletPointsHTML}
            </div>
    );
};

// Text **********************************************************************
export const Text = ({text, style, isNote=false, sectionTitle, sectionSubTitle, indent=true, textAlign="justify"}) => {
    return(
        <div className={`text-container ${isNote ? "notes-text" : ""}`}>
            {
                sectionTitle || sectionSubTitle
                ? <span>
                    {
                        sectionTitle 
                        ? <>
                            <h2>{sectionTitle}</h2>
                            <hr/>
                        </>
                        : <>
                            <h2 style={{"paddingBottom": "10px"}}>{sectionSubTitle}</h2>
                        </>
                    }
                </span>
                : <></>
            }
            <p 
                className={indent ? "indent" : ""} 
                style={
                    style 
                        ? {...style, textAlign: textAlign} 
                        : {textAlign: textAlign}
                    } 
            >
                {
                    text.map((entry) => {
                        if (typeof entry === "object" && !Array.isArray(entry)) {
                            return (
                                <span>
                                    {entry.leadText}
                                    <Bullets 
                                        returnRaw={entry?.returnRaw}
                                        bulletType={entry?.bulletType}
                                        bullets={entry?.bulletPoints}
                                    />
                                    <br/>
                                </span>
                            );
                        } else if (typeof entry === "string"
                                    && entry[0] === "<" 
                                    && entry[entry.length - 1] === ">") {
                            return parse(entry);
                        } else {
                            return entry;
                        }
                    })
                }
            </p>
        </div>
    );
};

// Image *********************************************************************
export const Image = ({src, caption, classNames, ID, style}) => {
    return (
        <div className={`image-container ${classNames}`} style={style}>
            <img id={`image-${ID}`} src={src} alt=""/>
            <caption style={{fontSize: "smaller"}}>{caption}</caption>
        </div>
    );
};

// Video *********************************************************************
export const Video = ({src, caption, classNames, ID, style}) => {
    return (
        <div className={`video-container ${classNames}`} style={style}>
            <video id={`video-${ID}`} 
                width="640" height="480" 
                autoPlay muted controls 
                preload="none">
                <source src={src} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <caption style={{fontSize: "smaller"}}>{caption}</caption>
        </div>
    );
};

// Tool **********************************************************************
// On hover, the tool reveals a note (tooltip) about its function
export const Tool = ({toolTip, tipPosition, classNames, IDs, content, toolFunction}) => {
  const [clicked, setClicked] =  React.useState(false);

    let tp;
    switch (tipPosition) {
        case "top":
        case "bottom":
            tp = tipPosition;
            break;
        default:
            tp = "top";
    }

    const flipClicked = () => {
        if (!clicked) {
            setClicked(true);
            if (toolFunction !== undefined) {
                toolFunction();
            }
            setTimeout(()=> {
                setClicked(false);
            }, 700);
        }
    }

    return (
        <button 
            className={`tooltip tip-${tp} ${classNames ? classNames : ""}`} 
            id={IDs} 
            onClick={flipClicked}
        >
            <span className="tooltiptext">{clicked ? toolTip[1] : toolTip[0]}</span>
            {clicked ? content[1] : content[0]}
        </button>
    );
};

// Code **********************************************************************
// Displays Github Gist code as a well as local code blocks. Code blocks need 
// to preseve white space (use `` strings) and should be used on small blocks.
export const Code = ({type, gistID, codeBlock, language, color, caption, style}) => {
    let returnBlock;

    switch (type) {
        case "gist":
            returnBlock = 
                <div className="code-container type-gist" style={style}>
                    <Gist id={gistID}/>
                    <caption style={{fontSize: "smaller"}}>{caption}</caption>
                </div>;
            break;
        case "highlight":
            returnBlock = 
                <div className="code-container type-highlight">
                    <Tool
                        toolTip={["copy snippet", "copied :)"]}
                        tipPosition="top"
                        content={[
                            <i class="fa-regular fa-clone"></i>, 
                            <i className="fa-solid fa-check"></i>
                        ]}
                        classNames="btn-highlighted btn-copy"
                        toolFunction={()=>{navigator.clipboard.writeText(codeBlock)}}
                    />
                    <SyntaxHighlighter 
                        language={language} 
                        style={solarizedlight}
                        showLineNumbers={true}
                    >
                        {String.raw`${codeBlock}`}
                    </SyntaxHighlighter>
                    <caption style={{fontSize: "smaller"}}>{caption}</caption>
                </div>;
            break;
        case "basic":
            const processedBlock = String.raw`${codeBlock}`;
            const preClassName = `preblock-${language !== undefined ? language : ""}`;
            const codeClassName = `codeblock-${language !== undefined ? language : ""}`;
            returnBlock = 
                <div className="code-container">
                    <Tool
                        toolTip={["copy snippet", "copied :)"]}
                        tipPosition="top"
                        content={[
                            <i id={`icon-code-${color}`} className="fa-regular fa-clone"></i>, 
                            <i id={`icon-code-${color}`} className="fa-solid fa-check"></i>
                        ]}
                        IDs={`btn-colored-${color}`}
                        classNames="btn-copy"
                        toolFunction={()=>{navigator.clipboard.writeText(processedBlock)}}
                    />
                    <pre className={preClassName} id={color}>
                        <code className={codeClassName} id={color}>
                            {processedBlock}
                        </code>
                    </pre>
                    <caption style={{fontSize: "smaller"}}>{caption}</caption>
                </div>;
                break;
        default:
            returnBlock = 
                <div className="code-container">
                    <pre className="pre-undefined">
                        <code className="code-undefined">
                            Error: Undefined code-block.
                        </code>
                    </pre>
                </div>
    }
    
    return returnBlock;
};

// LeftRightComponent ********************************************************
export const LeftRightComponent = ({leftComponent, rightComponent, classNames, shrinkLeft = false, shrinkRight = false}) => {
    return (
        <div className={`left-right-container ${classNames}`}>
            <div className={shrinkLeft ? "shrinked-left" : shrinkRight ? "enlarged-left" : "left"}>
                {leftComponent}
            </div>
            <div className={shrinkRight ? "shrinked-right" : shrinkLeft ? "enlarged-right" : "right"}>
                {rightComponent}
            </div>
        </div>
    );
};

// TopBottomComponent ********************************************************
// meant only to be used with basic code block
export const TopBottomComponent = ({topBlock, bottomBlock}) => {
    return (
        <div className="dual-code-container">
            <div className="top">
                {topBlock}
            </div>
            <div className="bottom">
                {bottomBlock}
            </div>
        </div>
    );
};

// Footer ********************************************************************
export const Footer = () => {
    return (
        <footer className="basic-footer"></footer>
    );
};

// P5 Canvas *****************************************************************
// Takes a function package (dictionary), containing unique preload, setup, and
// draw functions. Dictionary may also contain nessessary fields for functions
export const P5 =  ({fnPackages}) => {
    return (
        <div className="p5-container">
            {
                fnPackages.map((fnPackage, index) => {
                    const setup = (p5, canvasParentRef) => {
                        fnPackage.preload(p5);
                        fnPackage.setup(p5, canvasParentRef);
                    };
                    const draw = (p5) => {
                        fnPackage.draw(p5);
                    };
                    return (
                        <div className="canvas-container">
                            <Sketch 
                                key={`Sketch-Key-${index}`} 
                                setup={setup} 
                                draw={draw}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
};

// EmbeddedSite **************************************************************
// Uses Iframe to embed a link into the site
export const EmbeddedSite = ({ src, title, style }) => {
    return (
        <div className="iframe-container">
            <iframe 
                src={src} 
                title={title}
                style={style}
            ></iframe>
        </div>
    );
};

// Label *********************************************************************
export const Label = ({label}) => {
    let fontColor;
    let backgroundColor;
    switch (label.toLowerCase()) {

        // artificial intelligence
        case "a.i.":
        case "artificial intelligence":
        case "machine learning":
        case "deep learning":
        case "reinforcement learning":
        case "pytorch":
        case "sklearn":
        case "onnx":
        case "nlp":
        case "neural networks":
            fontColor = "white";
            backgroundColor = "#C19AB7"; // purple
            break;
        
        // game development
        case "game dev":
        case "game development":
        case "unity":
        case "unity engine":
            fontColor = "black";
            backgroundColor = "#59CD90"; // green
            break;
        
        // web development
        case "frontend":
        case "backend":
        case "fullstack":
        case "web dev":
        case "web development":
        case "react":
            fontColor = "white";
            backgroundColor = "#3FA7D6"; // blue
            break;
        
        // languages
        case "python":
        case "html":
        case "css":
        case "javascript":
        case "jsx":
        case "c#":
        case "assembly":
            fontColor = "black";
            backgroundColor = "#FAC05E"; // orange
            break;
        
        // frameworks 
        case "p5":
        case "p5.js":
        case "masm":
            fontColor = "white";
            backgroundColor = "#536d99"; // dark blue
            break;


        // research
        case "research":
            fontColor = "white";
            backgroundColor = "#34c0eb"; // blue
            break;

        // related research project
        case "related project":
            fontColor = "white";
            backgroundColor = "#11b02b"; // green
            break;
        
        default:
            fontColor = "white";
            backgroundColor = "#EE6352"; // red
            break;
    }

    return (
        <span 
            className="label"
            style={{
                color: fontColor,
                backgroundColor: backgroundColor
            }}
        ><b>{label}</b></span>
    );
};





// Back Button ***************************************************************
export const BackButton = () => {
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    return (
        <button 
            id="card-content-back-btn" 
            onClick={navigateBack}
        >
            <i className="fa-solid fa-angle-left"></i>
            &nbsp;
            back
        </button>
    );
}
 
// Card **********************************************************************
export const CardContent = ({cardData}) => {
    return (
        <div className="card-content">
            <BackButton/>
            {processComponentData(cardData)}
        </div>
    );
}

export const Card = ({data, mini=false}) => {
    const cardData = data;
    const navigate = useNavigate();

    const setContentToClickedCard = () => {
        if (cardData.projectID !== null && cardData.projectID !== undefined) {
            navigate('/projects/' + cardData.projectID);
        } else {
            console.log("Error navigating to project. Check card display component...)")
        }
    }

    return (
        <div className={mini ? "card-container-mini" : "card-container"} onClick={setContentToClickedCard}>
            <div className={mini ? "card-image-mini" : "card-image"}>
                <img
                    src={
                        cardData.cardImage.loadReady 
                        ? cardData.cardImage.src 
                        : getProjectMediaURL(cardData.cardImage.src)
                    }
                    alt=""
                />
            </div>
            <div className={mini ? "card-description-mini" : "card-description"}>
                <div id="card-description-text">
                    <h3 id="card-title">{cardData.name}</h3>
                    <p>{mini ? cardData.description.slice(0, 50) + "..." : cardData.description}</p>
                </div>
                
                {
                    mini
                    ? <></>
                    : <>
                        <hr className="card-divider"></hr>

                        <div className="card-segment">
                            <b>Topics:</b>
                            <div className="card-description-labels">
                                {
                                    cardData.tags.map((label) => {
                                        return <Label key={label + cardData.name} label={label}/>
                                    })
                                }
                            </div>
                        </div>

                        <div className="card-segment">
                            <b>Tools:</b>
                            <div className="card-description-labels">
                                {
                                    cardData.tools.map((label) => {
                                        return <Label key={label + cardData.name} label={label}/>
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
                
            </div>
        </div>
    );
};

// ImageContentPlaceHolder ********************************************************
export const ImageContentPlaceHolder = ({ size }) => {
    const styles = {
        small: {
            width: "100%",
            height: "300px"
        },
        medium: {
            width: "100%",
            height: "450px"
        },
        large: {
            width: "100%",
            height: "550px"
        }
    };

    return (
        <span 
            style={styles[size]}
            className="image-content-placeholder shimmerBG"
        >
            <i className="fa-regular fa-image"></i>
        </span>
    );
};

// Loading PlaceHolder ************************************************************
export const LoadingPlaceholder = ({ size }) => {

    const styles = {
        small: {
            width: "150px",
            height: "150px"
        },
        medium: {
            width: "300px",
            height: "300px"
        },
        large: {
            width: "450px",
            height: "450px"
        }
    };

    return (
        <div 
            style={styles[size]}
            className={`loading-wrapper-mini shimmerBG loading-style-${size}`}
        >
            <h3 style={{fontSize: "1em"}}>
                <i className="fa-solid fa-gear fa-spin"></i>
                <p>loading</p>
            </h3>
        </div>
    );
};

// Gradient Back ******************************************************************
export const GradientBack = ({ color=null, mini=false }) => {

    const style = {
        "veryberry-1" : {
            background: "linear-gradient(-45deg, #BEE7E8, #726DA8)"
        },
        "veryberry-2" : {
            background: "linear-gradient(-45deg, #726DA8, #BEE7E8)"
        },
        "veryberry-3" : {
            background: "linear-gradient(-45deg, #7D8CC4, #594157)"
        },
        "veryberry-4" : {
            background: "linear-gradient(-45deg, #594157, #7D8CC4)"
        },
        "contrast-1" : {
            background: "linear-gradient(-45deg, #ca94be, #fbfcb4)"
        },
        "contrast-2" : {
            background: "linear-gradient(-45deg, #cce2b8, #8d9c71)"
        },
        "contrast-3" : {
            background: "linear-gradient(-45deg, #e29191, #f2dfa7)"
        },
        "contrast-4" : {
            background: "linear-gradient(-45deg, #fe4a49, #fed766)"
        },
        "analagous-1" : {
            background: "linear-gradient(-45deg, #f4b6c2, #f6abb6)"
        },
        "analagous-2" : {
            background: "linear-gradient(-45deg, #03396c, #6497b1)"
        },
        "analagous-3" : {
            background: "linear-gradient(-45deg, #b3cde0, #03396c)"
        },
        "analagous-4" : {
            background: "linear-gradient(-45deg, #651e3e, #251e3e)"
        },
        "analagous-5" : {
            background: "linear-gradient(-45deg, #851e3e, #451e3e)"
        },
        "analagous-6" : {
            background: "linear-gradient(-45deg, #f9caa7, #fe9c8f)"
        },
        "analagous-7" : {
            background: "linear-gradient(-45deg, #faebd7, #fff5ee)"
        },
        "analagous-8" : {
            background: "linear-gradient(-45deg, #6e7f80, #36454f)"
        },
        "analagous-9" : {
            background: "linear-gradient(-45deg, #4b3832, #854442)"
        },
        "analagous-10" : {
            background: "linear-gradient(-45deg, #be9b7b, #3c2f2f)"
        },
        "analagous-11" : {
            background: "linear-gradient(-45deg, #4279a3, #49657b)"
        },
        "analagous-12" : {
            background: "linear-gradient(-45deg, #ff8b94, #ffaaa5)"
        },
        "analagous-13" : {
            background: "linear-gradient(-45deg, #FAC8CD, #98B6B1)"
        },
        "gradient-1": {
            background: "linear-gradient(-45deg,#ee9b52, #533ce7)"
        },
        "gradient-2": {
            background: "linear-gradient(-45deg,#ee9b52, #e73ccb)"
        },
        "gradient-3" : {
            background: "linear-gradient(-45deg, #9DA9A0, #654C4F)"
        },
        "gradient-4" : {
            background: "linear-gradient(-45deg, #205072, #329d9c)"
        },
        "gradient-5" : {
            background: "linear-gradient(-45deg, #56c596, #329d9c)"
        },
        "gradient-6" : {
            background: "linear-gradient(-45deg, #329d9c, #7be495)"
        },
        "test" : {
            background: "linear-gradient(-45deg, #c6c09c, #ffc98b)"
        },
        "gradient-8" : {
            background: "linear-gradient(-45deg, #ffcad4, #ffe5d8)"
        },
        "gradient-9" : {
            background: "linear-gradient(-45deg, #9f8189, #f3abb6)"
        },
        "gradient-10" : {
            background: "linear-gradient(-45deg, #aab6fb, #6096fd)"
        },
        "graident-11" : {
            background: "linear-gradient(-45deg, #031b88, #fb7b8e)"
        },
        "graident-12" : {
            background: "linear-gradient(-45deg, #534666, #dc8665)"
        },
        "graident-13" : {
            background: "linear-gradient(-45deg, #138086, #534666)"
        },
        "gradient-14" : {
            background: "linear-gradient(-45deg, #f9c449, #f04393)"
        },
        "gradient-15" : {
            background: "linear-gradient(-45deg, #ff9190, #fdc094)"
        },
        "gradient-16" : {
            background: "linear-gradient(-45deg, #fdc094, #5e72eb)"
        },
        "gradient-17" : {
            background: "linear-gradient(-45deg, #ff7b89, #a5cad2)"
        },
        "gradient-18" : {
            background: "linear-gradient(-45deg, #7facd6, #a5678e)"
        },
        "gradient-19" : {
            background: "linear-gradient(-45deg, #79616f, #7e9680)"
        },
        "gradient-20" : {
            background: "linear-gradient(-45deg, #f5cec7, #c9bbcb)"
        },
        "grey-dient-1" : {
            background: "linear-gradient(-45deg, #d1d1d1, #9b9b9b)"
        },
        "grey-dient-2" : {
            background: "linear-gradient(-45deg, #ffffff, #b6b6b6)"
        },
        "opacity-grid-right" : {
            zIndex:  "2",
            background: "linear-gradient(-45deg, #ffffff, #b6b6b6)"
        }
    }

    const availableStyles = Object.keys(style);

    // if style isn't specified it is selected randomly
    const selectedStyle = color === null || color === undefined
        ? availableStyles[Math.floor(Math.random() * availableStyles.length)] 
        : color;

    return (
        <div 
            style={style[selectedStyle]}
            className={`gradient-back-generic ${mini ? "mini" : ""} ${selectedStyle}`}
        ></div>
    );
};

// Research Paper Title ***********************************************************
export const ResearchPaperTitle = ({ id, title, authors, link, src, labels, lastUpdated, abstract, relatedProjectData }) => {
    return (
        <div className="research-paper-title-wrapper">

            <div className="research-paper-title-section-1">
                <h1>Research Paper Summary (#{id+1})</h1>
                <hr id="research-paper-title-hr"/>
                <h2 className="research-paper-title">
                    {title}&nbsp;
                    <a className="research-paper-link" 
                       href={link} target="_blank" 
                       rel="noopener noreferrer">
                        (link)
                    </a>
                </h2>
                <span className="research-paper-authors">
                    <h3 style={{ whiteSpace: "pre-wrap", color: "#7d7d7d" }}>
                        <span style={{color: "black"}}>Original Authors:&nbsp;</span>
                        {authors.map((author, index) => {
                            return (
                                index === authors.length - 1 
                                ? `and ${author}` 
                                : `${author}, `
                            );
                        })}
                    </h3>
                </span>
                <div className="research-paper-details">
                    <div className="research-paper-labels">
                        {labels.map((label) => {
                            return <Label key={label} label={label}/>;
                        })}
                    </div>
                    <h4 style={{color: "grey"}}>Last edited: {lastUpdated}</h4>
                </div>

            </div>

            <div className="research-paper-title-section-2">
                <div className="research-paper-title-image">
                    <img src={src} alt=""/>
                </div>
                <span className="research-title-abstract">
                    <h4>Quick Summary:</h4>
                    <br/>
                    <p>{abstract}</p>
                </span>
                {
                    relatedProjectData 
                    ? <div className="related-project">
                        <h4 style={{paddingLeft: "10px"}}>Related Project:</h4>
                        <br/>
                        <div className="related-project-container">
                            {generateCards([relatedProjectData], true)}
                        </div>
                    </div>
                    : <></>
                }
            </div>

        </div>
    );
};

// MathJax ************************************************************************
export const Equation = ({ text, style }) => {
    return (
        <span 
            className="math-equation"
            style={style}
        >{text}</span>
    );
};

// ComplexSection *****************************************************************
export const ComplexSection = ({ componentData, isSubsection=true }) => {
    return (
        <div
            className={`complex-section ${isSubsection ? "complex-subsection" : ""}`}
        >
            {processComponentData(componentData)}
        </div>
    );
};


// Copyright **********************************************************************
export const Copyright = ({ style, author, year, updated}) => {
    const copyrightYear = year 
        ? updated
            ? year === updated
                ? String(year)
                : `${year}, ${updated}`
            : String(year)
        : String(new Date().getFullYear());

    return (
        <span className="copyright" style={style}>
            © Copyright {author} {copyrightYear}
        </span>
    );
};