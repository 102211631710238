import * as React from "react";
import { useParams } from "react-router-dom";
import { scrollToTop } from "../utilities/utilities.js";
import * as DisplayComponents from "../components/DisplayComponents.js";
import "../css/Projects.css";
import { logAnalytic } from "../utilities/firebase.js";
import { constants } from "../utilities/constants.js";

const Project = ({data}) => {
    const params = useParams();

    React.useEffect(() => {
        scrollToTop();
        logAnalytic(`${constants.PROJECTS_ANALYTIC_EVENT}_${params.id}`);
    }, []);

    return (
        <div className="project-wrapper">
            <div className="project-card-container">
                <DisplayComponents.CardContent
                    // Array goes in decending order (newer projects come first)
                    // So index is calulated as length - project number - 1 (for dummy project card)
                    cardData={data[data.length - params.id - 2]}
                />
            </div>
        </div>
    );
};

export default Project;