import React from "react";
import * as DisplayComponents from "../components/DisplayComponents.js";
import "../css/TicTacToe.css";
import { 
    TicTacToeV1, 
    TicTacToeV2,
    TicTacToeVersions,
    TicTacToeV0
} from "./resources/TicTacToe.js";

const TicTacToe = ({ version }) => {
    const [instance, setInstance] = React.useState(null);
    const [board, setBoard] = React.useState(null);
    const [gameRecord, setGameRecord] = React.useState({
        gameWinner: null,
        gamesWonByPlayer: 0,
        gamesLostByPlayer: 0,
        gamesTied: 0
    });

    React.useEffect(() => {
        console.log(`TicTacToe Version: ${version}`);
        const initSession = async () => {
            let ttt;
            switch(version) {
                // case TicTacToeVersions.V0:
                //     // V0 uses MinMax algorithm
                //     ttt = new TicTacToeV0();
                //     break;
                case TicTacToeVersions.V1:
                    // V1 role is always X
                    ttt = new TicTacToeV1();
                    break;
                // case TicTacToeVersions.V2:
                //     // V2 role alternates
                //     ttt = new TicTacToeV2();
                //     break;
                case TicTacToeVersions.abstract:
                default:
                    ttt = new TicTacToeV1();
                    break;
            }
            await ttt.createSession();

            console.log(`TTT agent role: ${ttt.roleAsString(ttt.role)}, ${ttt.role}. Board: ${ttt.getBoard()}`);
            ttt.printBoard();

            setBoard(ttt.getBoard());
            setInstance(ttt);
        };
        initSession();
    }, []);

    const playerMove = async (index) => {
        const ib = instance.getBoard();
        if (ib[index] !== 0) {
            console.log(`(TicTacToe:playerMove) Index ${index} is taken. Board: ${ib}. Occupied by: ${ib[index]}`);
            return;
        }

        ib[index] = instance.getPlayerRole();
        instance.setBoard(ib);
        setBoard(ib);

        const gameOver = evaluateGameState();
        if (!gameOver) {
            await instance.takeAction();
            setBoard(instance.getBoard());
            evaluateGameState(instance.getBoard());
        }
    };

    /**
     * instance.isGameOver(): result can be...
     *  0:  tied
     *  1:  O
     * -1:  X
     *  2: game is ongoing
     * @returns boolean. Specifies whether game is over.
     */
    const evaluateGameState = () => {
        const [gameStatus, _] = instance.isGameOver();

        if (gameStatus === 2) {
            return false;
        } else if (gameStatus === instance.getRole()) {
            setGameRecord({
                ...gameRecord,
                gameWinner: instance.getRole(),
                gamesLostByPlayer: gameRecord.gamesLostByPlayer + 1
            });
            return true;
        } else if (gameStatus === instance.getPlayerRole()) {
            setGameRecord({
                ...gameRecord,
                gameWinner: instance.getPlayerRole(),
                gamesWonByPlayer: gameRecord.gamesWonByPlayer + 1
            });
            return true;
        } else if (gameStatus === 0) {
            setGameRecord({
                ...gameRecord,
                gameWinner: 0,
                gamesTied: gameRecord.gamesTied + 1
            });
            return true;
        }
    }

    const resetGame = async () => {
        await instance.resetGame();
        setBoard(instance.getBoard());
        setGameRecord({
            ...gameRecord,
            gameWinner: null
        });
    };

    return (
        <div className="tictactoe-wrapper">
            <div className="tictactoe-game">
                {
                    // wait for session to load
                    instance !== null && instance.session !== null
                        ? <div className="tictactoe-grid">
                            {
                                Array.from(Array(3).keys()).map((rowIndex) => {
                                    return (
                                        <div className="tictactoe-row">
                                            {
                                                Array.from(Array(3).keys()).map((colIndex) => {
                                                    const index = (rowIndex * 3) + colIndex
                                                    return (
                                                        <button
                                                            disabled={board[index] !== 0}
                                                            className="tictactoe-cell-btn"
                                                            onClick={async (event) => {
                                                                // make player move in index
                                                                await playerMove(index);
                                                            }}>
                                                            { instance.roleAsString(board[index]) }
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                        : <DisplayComponents.LoadingPlaceholder size="large"/>
                }
            </div>
            <div className="tictactoe-text">

                {
                    gameRecord.gameWinner === -1
                        ? <h4 id="tictactoe-loss"> You Lost! </h4>
                        : gameRecord.gameWinner === 1
                            ? <h4 id="tictactoe-win"> You Won! </h4>
                            : <h4 id="tictactoe-stats">Game Stats:</h4>
                }

                <hr/>
                <div className="tictactoe-gamestats">
                    <p>{`games won: ${gameRecord.gamesWonByPlayer} `}</p>
                    <p>{`games tied: ${gameRecord.gamesTied} `}</p>
                    <p>{`games lost: ${gameRecord.gamesLostByPlayer} `}</p>
                </div>
                <hr/>
                {
                    gameRecord.gameWinner !== null
                        ? <button
                            className="tictactoe-reset-btn"
                            onClick={async () => {await resetGame()}}
                        >
                            new game
                        </button>
                        : <></>
                }
            </div>
        </div>
    );
};

export default TicTacToe;
