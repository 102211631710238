import * as React from "react";
import "../css/Photography.css";
import { getURLsFromRefs, logAnalytic } from "../utilities/firebase";
import * as DisplayComponents from "../components/DisplayComponents.js";
import { ImageModal } from "./Artwork";
import { constants } from "../utilities/constants.js";

const getPlaceHolderImages = (n) => {
    return Array.from(Array(n).keys()).map((index) => {
        const p = Math.random();
        if (p <= 0.33) {
            return <DisplayComponents.ImageContentPlaceHolder size="small"/>;
        } else if (p <= 0.66) {
            return <DisplayComponents.ImageContentPlaceHolder size="medium"/>;
        } else {
            return <DisplayComponents.ImageContentPlaceHolder size="large"/>;
        }
    });
}

const GalleryPhoto = ({ src, setModalContent }) => {
    const openModal = () => {
        setModalContent(
            <ImageModal src={src} setModalContent={setModalContent}/>
        );
    };

    return (
        <div className="gallery-photo-card" onClick={openModal}>
            <span className="gallery-photo-cover">
                <i className="fa-solid fa-expand"></i>
            </span>
            <img className="gallery-photo-image" src={src} alt="cannot display"/>
        </div>
    );
}

const Photography = () => {
    const [selectedGallery, setSelectedGallery] = React.useState("People");
    const [galleryItems, setGalleryItems] =  React.useState([]);
    const [galleryContent, setGalleryContent] =  React.useState(
        // randomly load 10 placeholder image cards of varying sizes
        getPlaceHolderImages(10)
    );
    const [modalContent, setModalContent] =  React.useState(null);

    React.useEffect(() => {
        logAnalytic(constants.PHOTOGRAPHY_ANALYTIC_EVENT);
    }, [])

    React.useEffect(() => {
        const getContents = async () => {
            const path = `/images/galleries/${selectedGallery.toLowerCase()}`;

            // get urls and set the gallery state to trigger next useEffect
            getURLsFromRefs(path, setGalleryItems);
        }

        // set gallery to an image loading component
        setGalleryContent(getPlaceHolderImages(10));
        
        getContents();
    }, [selectedGallery]);

    React.useEffect(() => {
        if (Array.isArray(galleryItems) && galleryItems.length > 0) {
            setGalleryContent(
                galleryItems.map((url, index) => {
                    return(
                        <GalleryPhoto
                            key={`images/${selectedGallery}/${index}`}
                            src={url}
                            setModalContent={setModalContent}
                        />
                    );
                })
            );
        }
    }, [galleryItems]);

    return(
        <div className="photography-wrapper">
            <div className="gallery-grid">

                <p id="select">Select a gallery</p>
                <hr/><br/>

                <ul className="gal-select">
                    <li onClick={() => {if (selectedGallery !== "People") {setSelectedGallery("People")}}}
                        className={selectedGallery === "People" ? "gal gal-active" : "gal"}>People</li>
                    <li onClick={() => {if (selectedGallery !== "Places") {setSelectedGallery("Places")}}}
                        className={selectedGallery === "Places" ? "gal gal-active" : "gal"}>Places</li>
                    <li onClick={() => {if (selectedGallery !== "Structures") {setSelectedGallery("Structures")}}}
                        className={selectedGallery === "Structures" ? "gal gal-active" : "gal"}>Structures</li>
                    <li onClick={() => {if (selectedGallery !== "Things") {setSelectedGallery("Things")}}}
                        className={selectedGallery === "Things" ? "gal gal-active" : "gal"}>Things</li>
                </ul>
            </div>

            <div className="gallery-container">
                {galleryContent}
            </div>

            <span 
                className="photography-modal" 
                style={
                    modalContent === null 
                    ? {display: "none"}
                    : {display: "inherit"}
                }
            >
                {modalContent}
            </span>
        </div>
    );
}

export default Photography